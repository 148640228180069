.InputNumbers {
    position: relative;
    width: 100px;
    height: 40px;
    background-color: transparent;
    overflow: hidden;
    border-radius: 20px;
    margin: 2px;
    box-shadow: 4px 4px 8px #6C6DFF;
}

.all {
    display: flex;
    flex-direction: row;
    gap: 4.3rem;
}

@media screen and (max-width:700px) {
    .all {
        flex-direction: column;
        gap: 2rem !important;
    }

    .maleTitle {
        gap: 3rem !important;
    }

    .otherTitle {
        gap: 2.7rem !important;
    }
}

.titleNumbers {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    font-size: larger;
}

.InputNumbers span {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    display: inline-block;
    background-color: white;
    height: 100%;
    width: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #6C6DFF;

    letter-spacing: -1px;
}

.females {
    box-shadow: 4px 4px 8px rgb(80, 25, 50);

}

.females span {
    color: rgb(80, 25, 50) !important;
}

.others span {
    color: rgb(85, 61, 59) !important;

}

.others {
    box-shadow: 4px 4px 8px rgb(85, 61, 59);
}

.InputNumbers button {
    display: inline-block;
    width: 50px;
    height: 100%;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color 0.2s ease;
}

.InputNumbers button:nth-of-type(1) {
    padding-right: 20px;
}

.InputNumbers button:nth-of-type(2) {
    padding-left: 20px;
}

.InputNumbers button:hover {
    background-color: rgba(255, 255, 255, 0.25);
}