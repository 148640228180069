.input {
    gap: 8px;
    border-radius: 8px;
    border: 1.2px;
    width: 100%;
    margin-top: 24px;
  color: white !important;
    font-family: 'Inter', sans-serif !important;

  

    input {
      width: 100%;
      color: white !important;

      padding: 10px;
      gap: 8px;
      border-radius: 10px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      // opacity: 0px;
      border: 1.2px solid #E2E4E9;
      background-color: transparent;
      line-height: normal;
          // Autofill Fixes
    &:-webkit-autofill {
      -webkit-text-fill-color: white !important; // Force white text color
      box-shadow: 0 0 0 100px transparent inset !important; // Neutralize autofill background
      background-color: transparent !important; // Ensure no background
      transition: background-color 5000s ease-in-out 0s; // Smooth transition fix
    }

    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: white !important;
      box-shadow: 0 0 0 100px transparent inset !important;
      background-color: transparent !important;
    }
    }
    input:focus{
      outline: none;
  }
  }

  .submit {
    width: 100%;
    button {
      padding: 12px 8px 12px 8px;
      gap: 8px;
      border-radius: 20px;
      background: #ffffff;
      color: #000000;
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;

      line-height: normal;
      transition: transform 300ms ease-out;
      margin-top: 24px;
      cursor: pointer;
      &:disabled {
        background: #ffffff4d;
        cursor: not-allowed;
      }
      &:active {
        transform: scale(0.95); // Scale down slightly on click
      }
    }
  }

  .otherWay {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 24px;

    span {
      flex: 1;
      height: 1px;
      background: #66666659;
    }

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 24.2px;
      text-align: left;
      color: #ffffff;
      margin: 0 23px;
    }
  }

  .providedWays {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    margin-top: 24px;
    button {
      background: #ffffff !important;
      top: 577px;
      left: 639px;
      padding: 11.5px 38.5px 11.5px 39.5px;
      gap: 0px;
      border-radius: 40px;
      // opacity: 0px;
      // color: #000000;
      // font-size: 25px;
      line-height: 0px;
      svg {
        width: 33px;
        height: 33px;
      }
    }
  }

  .input {
    display: flex;
    align-items: center;
  color: white !important;

  }
  
  .flagAndCode {
    display: flex;
    align-items: center;
    margin-right: 8px;
  color: white !important;

  }
  
  .phoneInput {
    flex-grow: 1;
  color: white !important;

  }
  
  .phone_input {
    width: 100%;
  color: white !important;

  }

    /* HTML: <div class="loader"></div> */
.loader {
  width: 28px;
  aspect-ratio: .75;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  animation: l7 1s infinite linear alternate;
}
@keyframes l7 {
  0%  {background-size: 20% 50% ,20% 50% ,20% 50% }
  20% {background-size: 20% 20% ,20% 50% ,20% 50% }
  40% {background-size: 20% 100%,20% 20% ,20% 50% }
  60% {background-size: 20% 50% ,20% 100%,20% 20% }
  80% {background-size: 20% 50% ,20% 50% ,20% 100%}
  100%{background-size: 20% 50% ,20% 50% ,20% 50% }
}