.homepage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-top: 0.5rem;
  position: relative;
}

.bg {
  position: fixed;
  /* background-image: url("/assets/bgnew.jpg"); */
  background-color: black;
  height: 100%;
  top: 0;
  z-index: -1;
  /* filter: blur(30px); */
  background-size: cover;
  width: 100vw;
}

/* .fade {
  position: fixed;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(19, 23, 32));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgb(19, 23, 32));
  background: -moz-linear-gradient(rgba(0, 0, 0, 0), rgb(19, 23, 32));
  background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgb(19, 23, 32));
  height: 20vh;
  top: 49vh;

  width: 100vw;
} */