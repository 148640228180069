.dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000000;
    color: white;
    z-index: 999999;
    width: 80%;
    max-width: 450px;
    height: auto;
    padding: 16px;
    gap: 24px;
    border-radius: 24px;
    font-weight: 600;
    margin: 0 auto;
  }
  
  .progressBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .dash {
    flex: 1;
    height: 5px;
    margin: 0 5px;
    background: #ccc;
    border-radius: 3px;
  }
  
  .activeDash {
    background: #00b7ff;
  }
  
  .question {
    margin-bottom: 20px;
    width: 100%;
    margin: 0 5%;
  }
  
  .question label {
    display: block;
    font-weight: bold;
  }
  
  .radioGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
  
  .radioItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }
  
  .question select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
  }
  
  .actions  button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    line-height: normal;
  }
  
  .backButton {
    background: #000000;
    color: white;
    /* border: 2px solid white; */
  }
  
  .nextButton {
    background: white;
    color: #000000;
  }
  
  .submitButton {
    background: white;
    color: #000000;
  }
  
  .actions button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  