.search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: none;
    border-radius: 25px;
    z-index: 999;
    height: 40px;
    max-height: 200px;
    width: 100%;
    max-width: 400px;
    padding-left: 16px;


}

.search input {

    border: none;
    background-color: var(--search-bg);
    border-radius: 4px;
    font-family: var(--body-font);
    font-size: 15px;
    font-weight: 500;
    padding: 0 20px 0 40px;
    background-image: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23ffffff'><path d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/></svg>");
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 16px 48%;
    outline: none;

    /* Space for the search icon */
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    width: 300px;
    color: white;
}

.search input::placeholder {
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.list {
    position: absolute;
    top: 90px;
    left: 100px;
    z-index: 999;
    max-height: 300px;
    height: 250px;
    overflow-y: auto;
    border-radius: 15px;
}

.list ul {
    list-style-type: none;
    width: 350px;
    font-size: 13px;
    padding-top: 5px;
    border-radius: 15px;
    backdrop-filter: blur(70px);
    background-color: rgba(0, 0, 0, 0.2);
}

.list li {
    cursor: pointer;
    padding: 10px;
    width: 350px;
    border-radius: 15px;
}

.orgName {
    color: rgba(255, 255, 255, 0.7);
}

.org {
    color: #DA784B;
    display: flex;
}

.list img {
    float: left;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 10px 0 0;
}

.list span {
    display: block;
    width: unset;
    font-weight: bolder;
    line-height: 25px;
    border-radius: 15px;

}

.brick {
    width: 285px;
    height: 1px;
    background-color: #ccc;
    margin-left: 50px;
    border-radius: 15px;
}

.phone {
    font-weight: normal;
    font-style: italic;
    color: #999;
}