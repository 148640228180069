.logedIn {
  border-radius: 50%;
  /* background-image: var(--btn-gradient);
   */
  /* background-color: #1C212E; */

  height: 3rem;
  width: 3rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: white !important;
  color: black !important;
  font-size: 1rem;
  font-weight: 600;
}

.capitalize {
  text-transform: uppercase;
}

.isOpen {
  width: 150px;
  top: 6rem;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: none !important;
  z-index: 10;
  display: flex;
  flex-direction: column;
  right: 1.5rem;
  position: absolute;
  transition: all 2s ease-in-out;
}

.isAbso {
  width: 150px;
  top: 6rem;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: none !important;
  z-index: 10;
  display: flex;
  flex-direction: column;
  right: 1.5rem;
  position: absolute;
  transition: all 2s ease-in-out;
}

.li {
  text-decoration: none;
  padding: 0 1rem;
  list-style: none;
}

.li:hover {
  cursor: pointer;
  background-image: var(--btn-gradient);
  outline: none !important;
  color: white;
  position: relative;
  transition: all 2s ease-in-out;
}