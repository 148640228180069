@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); // Adjust the opacity as needed
    z-index: 999998; // Set below `.container` but above other content
  }
  .closeButton {
    z-index: 9999999; // Set below `.container` but above other content
    background-color: #FFFFFF;
    color: #000000;
    font-weight: 700;
    position: fixed;
    right: 20px;
    top: 20px;
    line-height: normal;
    padding: 8px 15px;
    border-radius: 50%;
  }
  .route {
    border: 1.5px solid white;
  }
.container {
  background-color: rgba($color: #000000, $alpha: 1);
  color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  width: 80%;
  max-width: 400px;
  height: 454px;
  padding: 16px;
  gap: 24px;
  border-radius: 24px;
  font-weight: 600;
  input {
    font-family: 'Inter', sans-serif !important;

  }
  button {
    font-family: 'Inter', sans-serif !important;

  }
  .header {
    .title {
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
    }
    .desc {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: center;
    }
  }
  .way {
    position: relative;
    padding: 4px 0 0 0;
    gap: 0;
    border-radius: 12px 0 0 0;
    display: flex;
    align-content: center;
    margin: 0;
    margin-top: 24px;
    justify-content: center;
    background-color: transparent; // Remove background from the main element
    
    // Add the background color with opacity using a pseudo-element
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.1); // Apply opacity here
      border-radius: 12px 0 0 0;
      z-index: 0;
    }
  
    // Set the stacking order for the inner content to be above the background
    * {
      position: relative;
      z-index: 1;
    }
  
    button {
      padding: 8px 16px;
      border-radius: 8px;
      background-color: #464748;
      width: 50%;
      font-size: 14px;
      margin: 0;
      line-height: normal;
        cursor: pointer;
    }
    .selected {
            background-color: #ffffff;
            color: #000000;
        
    }
  }
  
  .input {

gap: 8px;
border-radius: 8px ;
border: 1.2px ;
width: 100%;
margin-top: 24px;

input {
    width: 100%;

padding: 8px 16px 8px 16px;
gap: 8px;
border-radius: 8px ;
border: 1.2px 0px 0px 0px;
// opacity: 0px;
border: 1.2px solid #FFFFFF33;
background-color: transparent;
line-height: normal;
}
  }

  .submit {
    width: 100%;
    button {
        padding: 12px 8px 12px 8px;
        gap: 8px;
        border-radius: 20px ;
        background: #FFFFFF;
        color: #000000;
        width: 100%;
font-size: 16px;
font-weight: 700;
line-height: 24px;
text-align: center;

        line-height: normal;
        transition: transform 300ms ease-out;
        margin-top: 24px;
        cursor: pointer;
        &:disabled {
            background: #FFFFFF4D;
            cursor: not-allowed;
        }
        &:active {
          transform: scale(0.95); // Scale down slightly on click
        }
    }
  }

  .otherWay {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 24px;
  
    span {
      flex: 1;
      height: 1px;
      background: #66666659;

    }
  
    p {
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        text-align: left;
        color: #FFFFFF;
        margin: 0 23px;
    }
  }
  
  .providedWays {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    margin-top: 24px;
    button {
        background: #FFFFFF;
        top: 577px;
        left: 639px;
        padding: 11.5px 38.5px 11.5px 39.5px;
        gap: 0px;
        border-radius: 40px;
        border: 1px 0px 0px 0px;
        // opacity: 0px;
        color: #000000;
        // font-size: 25px;
        line-height: 0px;
        svg {
            width: 33px;
            height: 33px;
        }
    }
  }
}


@media screen and (max-width: 380px) {
  .closeButton {
    z-index: 9999999; // Set below `.container` but above other content
    background-color: #FFFFFF;
    color: #000000;
    font-weight: 700;
    position: fixed;
    right: 20px;
    top: 20px;
    line-height: normal;
    padding: 8px 15px;
    border-radius: 50%;
  }
  .route {
    border: 1.5px solid white;
  }
.container {
  background-color: rgba($color: #000000, $alpha: 1);
  color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  width: 80%;
  max-width: 400px;
  height: 454px;
  padding: 16px;
  gap: 24px;
  border-radius: 24px;
  font-weight: 600;

  .header {
    .title {
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
    }
    .desc {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: center;
    }
  }
  .way {
    position: relative;
    padding: 4px 0 0 0;
    gap: 0;
    border-radius: 12px 0 0 0;
    display: flex;
    align-content: center;
    margin: 0;
    margin-top: 24px;
    justify-content: center;
    background-color: transparent; // Remove background from the main element
    
    // Add the background color with opacity using a pseudo-element
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.1); // Apply opacity here
      border-radius: 12px 0 0 0;
      z-index: 0;
    }
  
    // Set the stacking order for the inner content to be above the background
    * {
      position: relative;
      z-index: 1;
    }
  
    button {
      padding: 8px 16px;
      border-radius: 8px;
      background-color: #464748;
      width: 50%;
      font-size: 10px;
      margin: 0;
      line-height: normal;
        cursor: pointer;
    }
    .selected {
            background-color: #ffffff;
            color: #000000;
        
    }
  }
  
  .input {

gap: 8px;
border-radius: 8px ;
border: 1.2px ;
width: 100%;
margin-top: 24px;

input {
    width: 100%;

padding: 8px 16px 8px 16px;
gap: 8px;
border-radius: 8px ;
border: 1.2px 0px 0px 0px;
// opacity: 0px;
border: 1.2px solid #FFFFFF33;
background-color: transparent;
line-height: normal;
}
  }

  .submit {
    width: 100%;
    button {
        padding: 12px 8px 12px 8px;
        gap: 8px;
        border-radius: 20px ;
        background: #FFFFFF;
        color: #000000;
        width: 100%;
font-size: 16px;
font-weight: 700;
line-height: 24px;
text-align: center;

        line-height: normal;
        transition: transform 300ms ease-out;
        margin-top: 24px;
        cursor: pointer;
        &:disabled {
            background: #FFFFFF4D;
            cursor: not-allowed;
        }
        &:active {
          transform: scale(0.95); // Scale down slightly on click
        }
    }
  }

  .otherWay {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 24px;
  
    span {
      flex: 1;
      height: 1px;
      background: #66666659;

    }
  
    p {
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        text-align: left;
        color: #FFFFFF;
        margin: 0 23px;
    }
  }
  
  .providedWays {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    margin-top: 24px;
    button {
        background: #FFFFFF;
        top: 577px;
        left: 639px;
        padding: 11.5px 38.5px 11.5px 39.5px;
        gap: 0px;
        border-radius: 40px;
        border: 1px 0px 0px 0px;
        // opacity: 0px;
        color: #000000;
        // font-size: 25px;
        line-height: 0px;
        svg {
            width: 33px;
            height: 33px;
        }
    }
  }
}
}
