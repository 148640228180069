/* VerifyEmail.module.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(77, 77, 77, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    color: white;
}

.inputField {
    padding: 0 1rem !important;
    outline: none;
    border-top: 3px solid #a8284a;
    border-bottom: 3px solid #da784b;
    background-color: transparent;
    color: white !important;
    border-left: 3px solid #a8284a;
    border-right: 3px solid #da784b;
    max-height: 3rem;
    border-radius: 20px;
    width: 100% !important;
    min-height: 3rem;
}

.inputField option {
    color: #000;
}

.modal {
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(-0%, -50%);
    background: white;
    padding: 1rem;
    border-radius: 10px;
    margin: 2rem;
    /* max-width: 90%; responsive width */
    width: 30%;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    opacity: 1;
    background-color: #1b212d;
    transition: opacity 0.5s ease-out;
}

.fadeOut {
    opacity: 0 !important;
}

.title {
    font-size: 1.2em;
    /* smaller title on mobile */
    font-weight: 600;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close {
    font-size: 1.2rem;
    color: white;
}

.btn {
    width: 2rem;
    height: 2rem;
    background-image: var(--btn-gradient);
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.body {
    font-size: 0.9em;
    /* smaller text on mobile */
    /* margin-bottom: 2em; */
    display: flex;
    gap: 1rem;
    overflow-y: auto;
}

.phone-flag {
    height: 60px !important;
    border-radius: 10px 0 0 10px !important;
    border: none !important;
    background-color: rgba(255, 255, 255, 0.13) !important;
    outline: none !important;
    position: relative;
    left: 2.5%;
    z-index: 1;
    color: black;
    width: 40px !important;
}

.note {
    font-size: 12px;
    line-height: normal;
}

.note span {
    background: linear-gradient(to right, #a8284a, #da784b);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.resendCode {
    font-size: 12px;
    color: red;
}

.phone_input {
    background-color: rgba(255, 255, 255, 0.13) !important;
    border-radius: 10px !important;
    border: none !important;
    outline: none !important;
    padding-left: 8px !important;
    width: 47.5% !important;
    position: relative;
    /* Add padding to accommodate the flag container */
    /* margin-left: -36px; */
    /* Adjust margin to align the input with the flag container */
    height: 60px !important;
    font-size: 16px !important;
    color: rgba(250, 250, 250, 0.53) !important;
    font-weight: 600;
}

.phone_input input {
    background-color: transparent !important;
    color: white;
    border: 1px solid transparent !important;
    width: 100%;
    outline: none;
    padding: 0.5rem;
}

.phone_input {
    margin-bottom: 20px;
    width: 100% !important;
}

/* .body div:first-child {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
} */

.status {
    font-size: 8rem;
    border-radius: 50%;
}

.success {
    fill: #04a24d;
}

.error {
    fill: #e21c3d;
}

.message {
    line-height: 30px;
}

.button {
    background-color: blue;
    color: white;
    padding: 0.5em 1em;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    /* full-width button on mobile */
}

.disabledBtn {
    background-color: rgb(66, 63, 63) !important;
    border-color: rgb(66, 63, 63) !important;
    background-image: none !important;
    cursor: not-allowed !important;
    box-shadow: 0 3px 20px 0 rgb(66, 63, 63) !important;
}

.btn_wrap {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
}

.select_field {
    position: relative;
    margin-bottom: 20px;
}

.select {
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 10px;
    border: none;
    padding: 0 0.5rem;
    outline: none;
    color: rgba(250, 250, 250, 0.53) !important;
    font-weight: 600;
    font-size: 16px !important;
    width: 100% !important;
    height: 60px !important;
}

.select option {
    background-color: #212121;
    /* Set the background color for the options */
    color: rgba(250, 250, 250, 0.53) !important;
}

.dob {
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 10px;
    border: none;
    padding: 0 0.5rem;
    outline: none;
    color: rgba(250, 250, 250, 0.53) !important;
    font-weight: 600;
    font-size: 16px !important;
    width: 100% !important;
    height: 60px !important;
}

.dateBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 0 !important;
    width: 100%;
}

.btn_wrap button {
    background-image: var(--btn-gradient);
    padding: 0 0.5rem;
    border-radius: 10px;
    width: 100%;

    cursor: pointer;
}

@media screen and (max-width: 1080px) {
    .modal {
        width: 50%;
    }
}

@media screen and (max-width: 850px) {
    .modal {
        width: 100%;
    }
}

@media screen and (max-width: 750px) {
    .modal {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .modal {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .modal {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .modal {
        width: 100%;
    }
}