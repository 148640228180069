.countryName {
  font-size: 1em;
  padding: 5px;
  margin-left: .6rem;
  display: flex;
  outline: none !important;
  font-family: "CyGroteskKeyBold.ttf", sans-serif;
}

.flag {
  width: 25px;
  margin-top: 10px;
}

.flags {
  width: 20px;
  margin-bottom: 3px;
}

.selected {
  background-image: var(--btn-gradient);
  color: white;
  outline: none !important;
  position: relative;
}

.relative {
  outline: none !important;
  position: relative;
}

.arrow {
  transition: transform 0.3s ease-in-out;
  outline: none !important;
  transform: rotate(0deg);
}

.countryName .arrow {
  outline: none !important;
  padding-top: 14px;
  margin-left: 0em;
}

.list.open .arrow {
  outline: none !important;
  transform: rotate(180deg);
}

.list {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: none !important;
  position: absolute;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  left: 1.5rem;
  transition: all 2s ease-in-out;
}

.listbox {
  max-width: 120px;
  outline: none !important;
  min-width: 120px;
  list-style: none;
}

.countryName {
  cursor: pointer;
  list-style: none;
  border-radius: 5px;
  max-width: 240px;
  padding: 0;
  min-width: 120px;
  outline: none !important;
}

.countryNameList {
  padding: 0 1rem;
  max-width: 120px;
  font-size: 1.4rem !important;
  min-width: 135px;
  outline: none !important;
  transition: all 2s ease-in-out;
  cursor: pointer;
  display: flex;
  gap: 5px;
}

.countryNameList:hover {
  background-image: var(--btn-gradient);
  outline: none !important;
  color: white;
  position: relative;
  transition: all 2s ease-in-out;
}

.list {
  outline: none !important;
  max-height: 115px;
  list-style: none;
  overflow-y: auto;
}

.option {
  outline: none !important;
  cursor: pointer;
}

.selected {
  outline: none !important;
  font-weight: bold;
}

@media screen and (max-width: 440px) {
  .countryName {
    outline: none !important;
  }
}

@media screen and (max-width: 400px) {
  .countryName {
    outline: none !important;
    margin-left: 0;
  }

  .list {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline: none !important;
    position: absolute;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    left: 0;
    transition: all 2s ease-in-out;
  }
}


.display {
  font-size: 2.5rem;
  font-family: "CyGroteskKeyBold.ttf", sans-serif;
}

@media screen and (max-width: 1000px) {
  .display {
    font-size: 2rem;

  }
}

@media screen and (max-width: 600px) {
  .display {
    font-size: 1.8rem;
  }

}

@media screen and (max-width: 400px) {
  .display {
    font-size: 1.4rem;

  }
}